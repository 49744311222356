p.MsoNormal {
    mso-style-name: 正文;
    mso-style-parent: "";
    margin-top: 3.0000pt;
    margin-bottom: 3.0000pt;
    mso-pagination: none;
    text-align: justify;
    text-justify: inter-ideograph;
    font-family: DINPro-Medium;
    color: rgb(51, 51, 51);
    font-size: 11.0000pt;
    mso-font-kerning: 1.0000pt;
}

h1 {
    mso-style-name: "标题 1";
    mso-style-next: 正文;
    margin-top: 3.0000pt;
    margin-bottom: 3.0000pt;
    page-break-after: avoid;
    mso-pagination: lines-together;
    text-align: justify;
    text-justify: inter-ideograph;
    mso-outline-level: 1;
    font-family: DINPro-Medium;
    color: rgb(51, 51, 51);
    mso-ansi-font-weight: bold;
    font-size: 18.0000pt;
    mso-font-kerning: 22.0000pt;
}

h2 {
    mso-style-name: "标题 2";
    mso-style-next: 正文;
    margin-top: 3.0000pt;
    margin-bottom: 3.0000pt;
    page-break-after: avoid;
    mso-pagination: lines-together;
    text-align: justify;
    text-justify: inter-ideograph;
    mso-outline-level: 2;
    font-family: DINPro-Medium;
    color: rgb(51, 51, 51);
    mso-ansi-font-weight: bold;
    font-size: 16.0000pt;
    mso-font-kerning: 1.0000pt;
}

h3 {
    mso-style-name: "标题 3";
    mso-style-next: 正文;
    margin-top: 3.0000pt;
    margin-bottom: 3.0000pt;
    page-break-after: avoid;
    mso-pagination: lines-together;
    text-align: justify;
    text-justify: inter-ideograph;
    mso-outline-level: 3;
    font-family: DINPro-Medium;
    color: rgb(51, 51, 51);
    mso-ansi-font-weight: bold;
    font-size: 14.0000pt;
    mso-font-kerning: 1.0000pt;
}

h4 {
    mso-style-name: "标题 4";
    mso-style-next: 正文;
    margin-top: 3.0000pt;
    margin-bottom: 3.0000pt;
    page-break-after: avoid;
    mso-pagination: lines-together;
    text-align: justify;
    text-justify: inter-ideograph;
    mso-outline-level: 4;
    font-family: DINPro-Medium;
    color: rgb(51, 51, 51);
    mso-ansi-font-weight: bold;
    font-size: 12.0000pt;
    mso-font-kerning: 1.0000pt;
}

h5 {
    mso-style-name: "标题 5";
    mso-style-next: 正文;
    margin-top: 3.0000pt;
    margin-bottom: 3.0000pt;
    page-break-after: avoid;
    mso-pagination: lines-together;
    text-align: justify;
    text-justify: inter-ideograph;
    mso-outline-level: 5;
    line-height: 200%;
    font-family: DINPro-Medium;
    color: rgb(51, 51, 51);
    mso-ansi-font-weight: bold;
    font-size: 11.0000pt;
    mso-font-kerning: 1.0000pt;
}

h6 {
    mso-style-name: "标题 6";
    mso-style-next: 正文;
    margin-top: 3.0000pt;
    margin-bottom: 3.0000pt;
    page-break-after: avoid;
    mso-pagination: lines-together;
    text-align: justify;
    text-justify: inter-ideograph;
    mso-outline-level: 6;
    line-height: 200%;
    font-family: DINPro-Medium;
    color: rgb(51, 51, 51);
    mso-ansi-font-weight: bold;
    font-size: 11.0000pt;
    mso-font-kerning: 1.0000pt;
}

p.MsoHeading7 {
    mso-style-name: "标题 7";
    mso-style-noshow: yes;
    mso-style-next: 正文;
    margin-top: 3.0000pt;
    margin-bottom: 3.0000pt;
    page-break-after: avoid;
    mso-pagination: lines-together;
    text-align: justify;
    text-justify: inter-ideograph;
    mso-outline-level: 7;
    line-height: 200%;
    font-family: DINPro-Medium;
    color: rgb(51, 51, 51);
    mso-ansi-font-weight: bold;
    font-size: 11.0000pt;
    mso-font-kerning: 1.0000pt;
}

p.MsoHeading8 {
    mso-style-name: "标题 8";
    mso-style-noshow: yes;
    mso-style-next: 正文;
    margin-top: 3.0000pt;
    margin-bottom: 3.0000pt;
    page-break-after: avoid;
    mso-pagination: lines-together;
    text-align: justify;
    text-justify: inter-ideograph;
    mso-outline-level: 8;
    line-height: 200%;
    font-family: DINPro-Medium;
    color: rgb(51, 51, 51);
    mso-ansi-font-weight: bold;
    font-size: 11.0000pt;
    mso-font-kerning: 1.0000pt;
}

p.Msoheading9 {
    mso-style-name: "标题 9";
    mso-style-noshow: yes;
    mso-style-next: 正文;
    margin-top: 3.0000pt;
    margin-bottom: 3.0000pt;
    page-break-after: avoid;
    mso-pagination: lines-together;
    text-align: justify;
    text-justify: inter-ideograph;
    mso-outline-level: 9;
    line-height: 200%;
    font-family: DINPro-Medium;
    color: rgb(51, 51, 51);
    mso-ansi-font-weight: bold;
    font-size: 11.0000pt;
    mso-font-kerning: 1.0000pt;
}

p.MsoSubtitle {
    mso-style-name: 副标题;
    mso-style-next: 正文;
    margin-top: 3.0000pt;
    margin-bottom: 3.0000pt;
    mso-pagination: none;
    text-align: center;
    mso-outline-level: 2;
    font-family: DINPro-Medium;
    color: rgb(51, 51, 51);
    font-weight: bold;
    font-size: 22.0000pt;
    mso-font-kerning: 14.0000pt;
}

p.MsoFooter {
    mso-style-name: 页脚;
    margin-top: 3.0000pt;
    margin-bottom: 3.0000pt;
    layout-grid-mode: char;
    mso-pagination: none;
    text-align: center;
    font-family: DINPro-Medium;
    color: rgb(51, 51, 51);
    font-size: 9.0000pt;
    mso-font-kerning: 1.0000pt;
}

p.MsoTitle {
    mso-style-name: 标题;
    mso-style-next: 正文;
    margin-top: 3.0000pt;
    margin-bottom: 3.0000pt;
    mso-pagination: none;
    text-align: center;
    mso-outline-level: 1;
    font-family: DINPro-Medium;
    color: rgb(51, 51, 51);
    font-weight: bold;
    font-size: 24.0000pt;
    mso-font-kerning: 1.0000pt;
}

p.MsoHeader {
    mso-style-name: 页眉;
    margin-top: 3.0000pt;
    margin-bottom: 3.0000pt;
    border-bottom: 1.0000pt solid windowtext;
    mso-border-bottom-alt: 0.7500pt solid windowtext;
    padding: 0pt 0pt 1pt 0pt;
    layout-grid-mode: char;
    mso-pagination: none;
    text-align: center;
    font-family: DINPro-Medium;
    color: rgb(51, 51, 51);
    font-size: 9.0000pt;
    mso-font-kerning: 1.0000pt;
}

p.MsoToc8 {
    mso-style-name: "目录 8";
    mso-style-next: 正文;
    margin-top: 3.0000pt;
    margin-bottom: 3.0000pt;
    margin-left: 147.0000pt;
    mso-para-margin-left: 14.0000gd;
    mso-pagination: none;
    text-align: justify;
    text-justify: inter-ideograph;
    font-family: DINPro-Medium;
    color: rgb(51, 51, 51);
    font-size: 11.0000pt;
    mso-font-kerning: 1.0000pt;
}

span.msoIns {
    mso-style-type: export-only;
    mso-style-name: "";
    text-decoration: underline;
    text-underline: single;
    color: blue;
}

span.msoDel {
    mso-style-type: export-only;
    mso-style-name: "";
    text-decoration: line-through;
    color: red;
}

table.MsoNormalTable {
    mso-style-name: 普通表格;
    mso-style-parent: "";
    mso-style-noshow: yes;
    mso-tstyle-rowband-size: 0;
    mso-tstyle-colband-size: 0;
    mso-padding-alt: 0.0000pt 5.4000pt 0.0000pt 5.4000pt;
    mso-para-margin: 0pt;
    mso-para-margin-bottom: .0001pt;
    mso-pagination: widow-orphan;
    font-family: DINPro-Medium;
    font-size: 10.0000pt;
}

table.MsoTableGrid {
    mso-style-name: 网格型;
    mso-tstyle-rowband-size: 0;
    mso-tstyle-colband-size: 0;
    mso-padding-alt: 0.0000pt 5.4000pt 0.0000pt 5.4000pt;
    mso-border-top-alt: 0.7500pt solid rgb(203, 205, 209);
    mso-border-left-alt: 0.7500pt solid rgb(203, 205, 209);
    mso-border-bottom-alt: 0.7500pt solid rgb(203, 205, 209);
    mso-border-right-alt: 0.7500pt solid rgb(203, 205, 209);
    mso-border-insideh: 0.7500pt solid rgb(203, 205, 209);
    mso-border-insidev: 0.7500pt solid rgb(203, 205, 209);
    mso-tstyle-vert-align: middle;
    mso-para-margin: 0pt;
    mso-para-margin-bottom: .0001pt;
    mso-pagination: widow-orphan;
    font-family: DINPro-Medium;
    font-size: 10.0000pt;
}

@page {
    mso-page-border-surround-header: no;
    mso-page-border-surround-footer: no;
}

@page Section0 {
    margin-top: 72.0000pt;
    margin-bottom: 72.0000pt;
    margin-left: 89.8500pt;
    margin-right: 89.8500pt;
    size: 595.3000pt 841.9000pt;
    layout-grid: 19.3500pt;
    mso-header-margin: 42.5500pt;
    mso-footer-margin: 49.6000pt;
}

div.Section0 {
    page: Section0;
}
